/* eslint-disable @typescript-eslint/no-shadow */
import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import config from './slicemachine.config.json';

/**
 * The project's Prismic repository name.
 */
export const repositoryName =
  process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
// TODO: Update the routes array to match your project's route structure.
const routes: prismic.ClientConfig['routes'] = [
  {
    type: 'home',
    path: '/',
  },
  {
    type: 'service_page',
    path: '/au/:uid',
  },
  {
    type: 'review_categories_group',
    path: '/review',
  },
  {
    type: 'review_category',
    path: '/reviews/:uid',
  },
  {
    type: 'review_category_page',
    path: '/reviews/:uid',
  },
  {
    type: 'review_post',
    path: '/reviews/details/:uid',
  },
  {
    type: 'blog_main_page',
    path: '/insights',
  },
  {
    type: 'blog_post',
    path: '/insights/details/:uid',
  },
  {
    type: 'blog_post_author',
    path: '/insights/author/:uid',
  },
  {
    type: 'blog_post_category',
    path: '/insights/:uid',
  },
  {
    type: 'careers_talk_main_page',
    path: '/careers/career-talk',
  },
  {
    type: 'careers_talk_post',
    path: '/careers/career-talk/:uid',
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
